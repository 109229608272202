const keypair = require("keypair");
import * as Comlink from "comlinkjs";
import axios from "axios";
import aesgcm from "aes-gcm-stream";
import concat from "concat-stream";
import { pki, util } from "node-forge";
import fileReaderStream from "filereader-stream";
import pako from "pako";
import Dexie from "dexie";
const db = new Dexie("files-dog");
import animals from "./animals.json";

const getFileName = () => {
  const animalName = animals[Math.floor(Math.random() * animals.length)];
  return `${animalName}-${parseInt(Math.random() * 1000)}.enc.gz`;
};

db.version(1).stores({
  keys: "name"
});

const decryptSender = ({ config, senderEncrypted }) => {
  return new Promise((resolve, reject) => {
    const decrypt = aesgcm.decrypt(config);

    decrypt.pipe(
      concat(data => {
        resolve(data.toString());
      })
    );
    const buffer = Buffer.from(senderEncrypted, "base64");

    decrypt.write(buffer);
    decrypt.end("");
  });
};

const encryptSender = ({ config, email }) => {
  return new Promise((resolve, reject) => {
    const encrypt = aesgcm.encrypt(config);

    encrypt.pipe(
      concat(data => {
        resolve(data.toString("base64"));
        // resolve(data);
      })
    );
    encrypt.write(email);
    encrypt.end("");
  });
};

class MyWorker {
  async createKeypair(cb) {
    const kp = keypair();
    const myPublicKeyEncoded = encodeURIComponent(
      new Buffer(kp.public).toString("base64")
    );

    const res = (await db.keys.get("keys")) || {};
    res.values = res.values || {};

    res.values[myPublicKeyEncoded] = {
      privateKeyString: kp.private,
      createdAt: new Date()
    };

    db.keys
      .put({
        name: "keys",
        values: res.values
      })
      .then(result => {
        cb(myPublicKeyEncoded);
      });
    // db.keys
    //   .put({
    //     publicKeyEncoded: myPublicKeyEncoded,
    //     privateKey: kp.private
    //   })
    //   .then(result => {
    //     cb(myPublicKeyEncoded);
    //   });
  }

  async getMeta({ fileId, access_token }, cb) {
    const result = await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?fields=name,description`,
      {
        method: "GET",
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${access_token}`
        }
      }
    ).then(res => res.json());

    // console.log(result);
    if (!result.description) {
      cb({ error: "file not found." });
      return;
    }

    let description = JSON.parse(result.description);

    const {
      mimeType,
      senderEncrypted,
      keyEncoded,
      publicKeyEncoded,
      originalNameEncoded
    } = description;

    const keyEncrypted = util.decode64(keyEncoded);

    const { values } = await db.keys.get("keys");

    const { privateKeyString } =
      values[encodeURIComponent(publicKeyEncoded)] ||
      values[publicKeyEncoded] ||
      {}; // publicKeyEncoded has been decoded again when uploaded.

    if (!privateKeyString) {
      cb({ error: "private key not found." });
      return;
    }

    const privateKey = pki.privateKeyFromPem(privateKeyString);
    const key = privateKey.decrypt(keyEncrypted);
    const config = { key };

    const originalName = util.decodeUtf8(
      privateKey.decrypt(util.decode64(originalNameEncoded))
    );

    const email = await decryptSender({ config, senderEncrypted });

    cb({
      mimeType,
      publicKeyEncoded,
      originalName,
      emailRestored: email,
      randomName: result.name,
      keyEncrypted,
      error: null
    });
  }
  async download(
    { fileId, access_token, mimeType, keyEncrypted, publicKeyEncoded },
    cb,
    progressCallback
  ) {
    const getDecrypt = async () => {
      const { values } = await db.keys.get("keys");
      // console.log(values);
      // console.log(publicKeyEncoded);

      const { privateKeyString } =
        values[encodeURIComponent(publicKeyEncoded)] ||
        values[publicKeyEncoded]; // publicKeyEncoded has been decoded again when uploaded.

      const privateKey = pki.privateKeyFromPem(privateKeyString);
      const key = privateKey.decrypt(keyEncrypted);
      const config = { key };

      const decrypt = aesgcm.decrypt(config);

      return {
        decrypt
      };
    };

    const { decrypt } = await getDecrypt();

    progressCallback({ downloadStatus: 1 });

    const ab = await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }
    ).then(res => res.arrayBuffer());

    progressCallback({ downloadStatus: 2 });

    decrypt.pipe(
      concat(data => {
        const blob = new Blob([data], { type: mimeType });
        const url = URL.createObjectURL(blob);
        progressCallback({ downloadStatus: 3 });
        cb({ url });
      })
    );

    const inflator = new pako.Inflate();

    inflator.onData = data => {
      decrypt.write(data);
    };

    inflator.onEnd = () => {
      decrypt.end();
    };

    inflator.push(ab);

    inflator.push([], true);
  }
  async encrypt(
    { url, access_token, email, name, type, publicKeyEncoded },
    onEnd,
    onProgress
  ) {
    onProgress("start encrypt");
    const key = aesgcm.createEncodedKey();
    const aesConfig = {
      key
    };

    const publicKey = atob(decodeURIComponent(publicKeyEncoded));
    const pub = pki.publicKeyFromPem(publicKey);
    const encrypted = util.encode64(pub.encrypt(key));
    // const originalNameEncoded = util.encode64(pub.encrypt(name));
    const originalNameEncoded = util.encode64(
      pub.encrypt(util.encodeUtf8(name))
    );

    const senderEncrypted = await encryptSender({ config: aesConfig, email });

    const encrypt = aesgcm.encrypt(aesConfig);

    const deflator = new pako.Deflate();

    encrypt.on("data", data => {
      //   console.log(buf);
      //   buf.push(data);
      deflator.push(data);
    });

    encrypt.on("end", async () => {
      onProgress("start upload");

      deflator.push([], true);
      const headers = {
        "Content-Type": "application/octet-stream",
        // "Content-Length": file.size,
        Authorization: `Bearer ${access_token}`
      };

      const body = deflator.result;

      const data = await fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=media",
        {
          method: "POST",
          headers,
          body
        }
      ).then(res => res.json());

      await fetch(`https://www.googleapis.com/drive/v3/files/${data.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
          // name: `${name}.enc.gz`, // debug
          name: getFileName(),
          description: JSON.stringify({
            keyEncoded: encrypted.toString("base64"),
            publicKeyEncoded,
            mimeType: type,
            senderEncrypted,
            originalNameEncoded
          })
        })
      });

      await fetch(
        `https://www.googleapis.com/drive/v3/files/${data.id}/permissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${access_token}`
          },
          body: JSON.stringify({
            role: "reader",
            type: "anyone"
          })
        }
      );

      // console.log(`https://drive.google.com/uc?&id=${data.id}`);

      onEnd({ fileId: data.id });
    });

    const blob = await fetch(url).then(res => res.blob());
    const file = new File([blob], name);
    fileReaderStream(file).pipe(encrypt);
  }
  async loadFile(url, cb) {
    const ab = await fetch(url).then(res => res.arrayBuffer());
    cb(ab);
  }
}

Comlink.expose(MyWorker, self);
